<template>
    <div class="mt-4 w-100">
        <vue-headful :title="$t('reports.dashboard.title')" description="title" />
        <h5 class="text-h4 my-6" style="font-weight: bolder">
            {{ $t('diagnoses.titles.evaluations') }}
        </h5>
        <div>
            <BeeTabs
                data-cy="tab-evaluations-btn"
                :items="tabItems"
                :textAlign="`left`"
                @changeTab="setCurrentTab"
                :tabSelected="currentTab"
            />
            <!-- <v-row v-if="isError" class="px-2 py-4">
                <v-alert color="red" dense outlined rounded="xl" text type="error" dismissible @input="closeAlert">
                  {{ getErrorMessage }}
                </v-alert>
            </v-row> -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import BeeTabs from '@/components/core/BeeTabs'

export default {
    name: 'Dashboard',
    components: {
        BeeTabs,
    },
    data() {
        return {
            currentTab: 0,
        }
    },
    computed: {
        tabItems() {
            const items = [
                {
                    icon: 'mdi-chart-timeline-variant-shimmer',
                    text: this.$t('diagnoses.titles.evaluations'),
                },
                {
                    icon: 'mdi-book-open',
                    text: this.$t('diagnoses.titles.assignedEvaluations'),
                }
            ]

            return items
        },
    },
    beforeMount() {
        this.checkRoute()
    },
    methods: {
        ...mapActions('reports', ['clearErrors']),
        setCurrentTab(tab) {
            this.currentTab = tab
            switch (tab) {
                case 0:
                    if (this.$router.history.current.name.toLowerCase() === 'evaluations') return
                    return this.$router.push(`/teacher/diagnoses/evaluations`)
                case 1:
                    if (this.$router.history.current.name.toLowerCase() === 'assigned_evaluations')
                        return
                    return this.$router.push(`/teacher/diagnoses/assigned`)
                case 2:
                    if (this.$router.history.current.name.toLowerCase() === 'results') return
                    return this.$router.push(`/teacher/diagnoses/results`)
            }
        },
        closeAlert() {
            this.clearErrors()
        },
        checkRoute() {
            switch (this.$router.history.current.name.toLowerCase()) {
                case 'evaluations':
                    this.setCurrentTab(0)
                    break
                case 'assigned_evaluations':
                    this.setCurrentTab(1)
                    break
                case 'results':
                    this.setCurrentTab(2)
                    break
            }
        },
    },
    watch: {
        $route() {
            this.checkRoute()
        },
    },
}
</script>
